@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  fieldset[disabled] input,
  fieldset[disabled] textarea,
  input[disabled],
  textarea[disabled] {
    @apply grayscale;
    @apply cursor-not-allowed;
  }
  input[type="radio"], input[type="checkbox"] {
    @apply h-6 w-6;
  }

  .heading1 {
    @apply font-roboto;
    @apply text-4xl;
    @apply font-bold;
    @apply text-blue-600;
    @apply mt-12;
    @apply mb-8;
    @apply after:before:content-[""] after:block after:m-0 after:w-12 after:pt-1 after:border-b-4 after:border-red-200;
    @apply lg:text-5xl lg:mt-16;
  }
  .heading2 {
    @apply font-roboto;
    @apply text-3xl;
    @apply font-bold;
    @apply text-blue-600;
    @apply mt-12;
    @apply mb-4;
    @apply lg:text-4xl;
  }
  .heading3 {
    @apply font-roboto;
    @apply text-2xl;
    @apply font-bold;
    @apply text-blue-600;
    @apply mt-12;
    @apply mb-4;
    @apply lg:text-3xl;
  }
  .heading4 {
    @apply font-roboto;
    @apply text-xl;
    @apply font-bold;
    @apply text-blue-600;
    @apply mt-6;
    @apply mb-2;
  }
  .heading5 {
    @apply font-roboto;
    @apply text-lg;
    @apply font-bold;
    @apply text-blue-600;
    @apply mt-4;
    @apply mb-0;
  }
  p,
  .paragraph,
  label,
  li {
    @apply font-openSans;
    @apply text-base;
    @apply font-normal;
    @apply text-blue-600;
    @apply my-0;
  }
  a {
    @apply font-openSans;
    @apply text-blue-400;
    @apply no-underline;
    @apply hover:underline;
  }
  ul {
    @apply list-disc;
    @apply pl-4;
  }

  select:has(option:disabled:checked[hidden]) {
    @apply text-gray-300;
  }

   .gpa-pill, a:hover>.gpa-pill {
    @apply no-underline !important;
  }
}
